const dataSourse = [
  {
    label: '项目地址',
    value: 'address',
    text: '北京市东城区东长安街',
  },
  {
    label: '订单号',
    value: 'orderNo',
    text: '0001-01',
  },
  {
    label: '客户',
    value: 'customer_name',
    text: '张某某',
  },
  {
    label: '设计师',
    value: 'designer',
    text: '李设计',
  },
  {
    label: '房间',
    value: 'roomName',
    text: '主卧',
  },
  {
    label: '柜体',
    value: 'loc',
    text: '衣柜',
  },
  {
    label: '板件名',
    value: 'partName',
    text: '次卧_柜A_横隔板01',
  },
  {
    label: '板件成品尺寸(长x宽x厚)',
    value: 'oSize',
    text: '2440X1220X18',
  },
  {
    label: '板件成品尺寸(仅长宽)',
    value: 'oSize_L',
    text: '1220X2440',
  },
  {
    label: '板件成品尺寸(宽x长x厚)',
    value: 'oSize_L_W_T',
    text: '1220X2440X18',
  },
  {
    label: '板件材质和颜色',
    value: 'matCode_texture',
    text: '多层实木-T01',
  },
  {
    label: '板件材质',
    value: 'matCode',
    text: '多层实木',
  },
  {
    label: '板件颜色',
    value: 'texture',
    text: 'T01',
  },
  {
    label: '侧孔信息',
    value: 'sholeInfo',
    text: '1←2↓3→4↑',
  },
  {
    label: '侧孔信息(翻面)',
    value: 'sholeInfoF',
    text: '1←2↓3→4↑',
  },
  {
    label: '侧槽信息',
    value: 'sslotInfo',
    text: '1←2↓3→4↑',
  },
  {
    label: '侧槽信息(翻面)',
    value: 'sslotInfoF',
    text: '1←2↓3→4↑',
  },
  {
    label: '小板序号',
    value: 'plankID',
    text: '11#',
  },
  {
    label: '小板序号（不带#号）',
    value: 'onlyPlankID',
    text: '11',
  },
  {
    label: '海棠角',
    value: 'handleSlopes',
    text: '海棠角',
  },
  {
    label: '大板序号',
    value: 'bigPlankID',
    text: '1',
  },
  {
    label: '第几张大板',
    value: 'plank_index',
    text: '第1 张大板',
  },
  {
    label: '标签页码',
    value: 'lable_index',
    text: '第1/10页',
  },
  {
    label: '拉手名称',
    value: 'handle_name',
    text: '单孔拉手',
  },
  {
    label: '拉直器名称',
    value: 'straightener_label',
    text: '默认拉直器',
  },
  {
    label: '铰链名称',
    value: 'hinge_label',
    text: '默认铰链',
  },
  {
    label: '切割尺寸',
    value: 'cutSize',
    text: '200*200*18',
  },
  {
    label: '安装编号',
    value: 'installNum',
    text: '10',
  },
  {
    label: '订单备注',
    value: 'remarks',
    text: '订单备注',
  },
  {
    label: '正面加工标注',
    value: 'frontProcess',
    text: '加工正面',
  },
  {
    label: '反面加工标注',
    value: 'reverseProcess',
    text: '加工反面',
  },
  {
    label: '侧面加工标注',
    value: 'sideProcess',
    text: '加工侧面',
  },
  {
    label: '排版时间',
    value: 'paibanTime',
    text: '2022/01/01 00:00:00',
  },
  {
    label: '板件标注',
    value: 'plankSign',
    text: '板件标注',
  },
  // 包数为老板良定制用户所拥有功能
  {
    label: '包数',
    value: 'packageCount',
    text: '1',
  },
  // 板件条码
  {
    label: '板件条码',
    value: 'oriPlankNum',
    text: '6160479583801',
  },
  // 铰链规格
  {
    label: '铰链规格',
    value: 'partsChildren',
    text: '内嵌式_默认铰链',
  },
  {
    label: '特殊工艺',
    value: 'process_label',
    text: '无',
  },
  // 小板孔槽总数
  {
    label: '小板孔槽总数',
    value: 'hsInfoTotal',
    text: '0孔0槽',
  },
  // 铰链孔位置
  {
    label: '铰链孔位置',
    value: 'hingeHoleLocationData',
    text: '铰链孔位置',
  },
  // 板件备注
  {
    label: '板件备注',
    value: 'plank_remarks',
    text: '板件备注',
  },
  // 房间备注
  {
    label: '房间备注',
    value: 'remark',
    text: '房间备注',
  },
  // 铰链位置
  {
    label: '铰链位置',
    value: 'hingeInfo',
    text: '铰链位置',
  },
  {
    label: '封边信息',
    value: 'edgeInfomation',
    text: '封边信息',
  },
  {
    label: '同尺寸小板数量总计（矩形板）',
    value: 'sameSizeBoardCount',
    text: '同尺寸小板数量总计（矩形板）',
  },
]
const bgColor = [
  {
    label: 20,
    value: '#000000',
  },
  {
    label: 19,
    value: '#0D0D0D',
  },
  {
    label: 18,
    value: '#1B1B1B',
  },
  {
    label: 17,
    value: '#282828',
  },
  {
    label: 16,
    value: '#363636',
  },
  {
    label: 15,
    value: '#434343',
  },
  {
    label: 14,
    value: '#515151',
  },
  {
    label: 13,
    value: '#5E5E5E',
  },
  {
    label: 12,
    value: '#6B6B6B',
  },
  {
    label: 11,
    value: '#797979',
  },
  {
    label: 10,
    value: '#868686',
  },
  {
    label: 9,
    value: '#949494',
  },
  {
    label: 8,
    value: '#A1A1A1',
  },
  {
    label: 7,
    value: '#AEAEAE',
  },
  {
    label: 6,
    value: '#BCBCBC',
  },
  {
    label: 5,
    value: '#C9C9C9',
  },
  {
    label: 4,
    value: '#D7D7D7',
  },
  {
    label: 3,
    value: '#E4E4E4',
  },
  {
    label: 2,
    value: '#EEEEEE',
  },
  {
    label: 1,
    value: '#FFFFFF',
  },
]
// const codeAttOpeion = ["EAN13","Code128","Codabar","Code39","Code39Extended","Code93","Code93Extended","Code11","CodeMSI","PostNet","UPCA","EAN8","EAN128","PDF417","DataMateix","QECode"]
const codeAttOpeion = [
  'EAN13',
  'CODE128',
  'CODE128A',
  'CODE128B',
  'codabar',
  'CODE39',
  'MSI10',
  'MSI11',
  'MSI1010',
  'MSI1110',
  'GenericBarcode',
]
const codeAttOpeionK = [
  'CODE128',
  'CODE128A',
  'CODE128B',
  'CODE39',
  'GenericBarcode',
]
const defaultTagTem = {
  name: '自定义标签',
  data: {
    tem_data: [
      {
        type: 'FixedText',
        data: {
          text: '客户：',
          lineHeight: 1,
          fontSize: 10,
          fontWeight: '',
          item_id: 'FixedText_0',
          fontPt: 8,
          hasControls: false,
          left: 520.5,
          top: 270.55555555555554,
        },
      },
      {
        type: 'DataSource',
        data: {
          text: '张某某',
          lineHeight: 1,
          fontSize: 10,
          fontWeight: '',
          item_id: 'DataSource_3',
          fontPt: 8,
          hasControls: false,
          left: 521.1666666666667,
          top: 287.33333333333326,
        },
      },
      {
        type: 'FixedText',
        data: {
          text: '订单号：',
          lineHeight: 1,
          fontSize: 10,
          fontWeight: '',
          item_id: 'FixedText_1',
          fontPt: 8,
          hasControls: false,
          left: 519.5,
          top: 320.1111111111111,
        },
      },
      {
        type: 'FixedText',
        data: {
          text: '成品尺寸：',
          lineHeight: 1,
          fontSize: 10,
          fontWeight: '',
          item_id: 'FixedText_2',
          fontPt: 8,
          hasControls: false,
          left: 548.9444444444446,
          top: 270.6666666666667,
          source_data: 'customer_name',
          textBackgroundColor: '#F6F6F6',
        },
      },
      {
        type: 'DataSource',
        data: {
          text: '0001-01',
          lineHeight: 1,
          fontSize: 10,
          fontWeight: '',
          item_id: 'DataSource_4',
          fontPt: 8,
          hasControls: false,
          left: 559.5,
          top: 287.8888888888888,
          source_data: 'orderNo',
          textBackgroundColor: '#F6F6F6',
        },
      },
      {
        type: 'DataSource',
        data: {
          text: '主卧',
          lineHeight: 1,
          fontSize: 10,
          fontWeight: '',
          item_id: 'DataSource_5',
          fontPt: 8,
          hasControls: false,
          left: 558.9444444444445,
          top: 320.1111111111111,
          source_data: 'roomName',
          textBackgroundColor: '#F6F6F6',
        },
      },
      {
        type: 'FixedText',
        data: {
          text: '项目地址：',
          lineHeight: 1,
          fontSize: 10,
          fontWeight: '',
          item_id: 'FixedText_6',
          fontPt: 8,
          hasControls: false,
          left: 520.0555555555557,
          top: 303.44444444444446,
        },
      },
      {
        type: 'DataSource',
        data: {
          text: '北京市东城区东长安街',
          lineHeight: 1,
          fontSize: 10,
          fontWeight: '',
          item_id: 'DataSource_7',
          fontPt: 8,
          hasControls: false,
          left: 567.2777777777776,
          top: 304,
          source_data: 'address',
          textBackgroundColor: '#F6F6F6',
        },
      },
      {
        type: 'DataSource',
        data: {
          text: '衣柜',
          lineHeight: 1,
          fontSize: 10,
          fontWeight: '',
          item_id: 'DataSource_10',
          fontPt: 8,
          hasControls: false,
          left: 550.6111111111111,
          top: 337.3333333333333,
          source_data: 'loc',
          textBackgroundColor: '#F6F6F6',
        },
      },
      {
        type: 'FixedText',
        data: {
          text: '柜体：',
          lineHeight: 1,
          fontSize: 10,
          fontWeight: '',
          item_id: 'FixedText_11',
          fontPt: 8,
          hasControls: false,
          left: 520.6111111111112,
          top: 336.77777777777777,
        },
      },
      {
        type: 'FixedText',
        data: {
          text: '安装图',
          lineHeight: 1,
          fontSize: 10,
          fontWeight: '',
          item_id: 'FixedText_14',
          fontPt: 8,
          hasControls: false,
          left: 522.8333333333334,
          top: 400.1111111111112,
        },
      },
      {
        type: 'DataSource',
        data: {
          text: '第1 张大板',
          lineHeight: 1,
          fontSize: 10,
          fontWeight: 'bold',
          item_id: 'DataSource_15',
          fontPt: 8,
          hasControls: false,
          left: 682.2777777777778,
          top: 372.8888888888888,
          source_data: 'plank_index',
          textBackgroundColor: '#F6F6F6',
        },
      },
      {
        type: 'DataSource',
        data: {
          text: '第1/10页',
          lineHeight: 1,
          fontSize: 10,
          fontWeight: 'bold',
          item_id: 'DataSource_16',
          fontPt: 8,
          hasControls: false,
          left: 687.2777777777776,
          top: 390.6666666666667,
          source_data: 'lable_index',
          textBackgroundColor: '#F6F6F6',
        },
      },
      {
        type: 'Typography',
        data: {
          imgItem: {
            left: 10,
            top: 10,
            mWidth: 15,
            mHeight: 25,
          },
          textItems: [
            {
              positionName: 'LE',
              top: 47.245,
              left: 0,
              fontSize: 8,
              fontPt: 8,
              fontWeight: '',
            },
            {
              positionName: 'BE',
              top: 94.49,
              left: 28.345,
              fontSize: 8,
              fontPt: 8,
              fontWeight: '',
            },
            {
              positionName: 'RE',
              top: 47.245,
              left: 56.69,
              fontSize: 8,
              fontPt: 8,
              fontWeight: '',
            },
            {
              positionName: 'TE',
              top: 0,
              left: 28.345,
              fontSize: 8,
              fontPt: 8,
              fontWeight: '',
            },
          ],
          hasControls: false,
          item_id: 'Typography_9',
          left: 676.7222222222223,
          top: 270.66666666666663,
          mHeight: 25,
          mWidth: 15,
          scaleX: 1,
          scaleY: 1,
          width: 56.69,
          height: 94.49,
        },
      },
      {
        type: 'QRcode',
        data: {
          width: 320,
          height: 324,
          hasControls: false,
          item_id: 'QRcode_12',
          left: 520.6111111111112,
          top: 358.99999999999994,
          source_data: 'ggid',
          mHeight: 10,
          mWidth: 10,
          scaleX: 0.118125,
          scaleY: 0.11666666666666665,
        },
      },
      {
        type: 'Onecode',
        data: {
          width: 113.39,
          height: 37.8,
          hasControls: false,
          item_id: 'Onecode_13',
          left: 561.7483660130717,
          top: 360.5882352941177,
          mHeight: 10,
          mWidth: 30,
          scaleX: 0.8571968551557303,
          scaleY: 1,
          fontPt: 8,
          lineHeight: 1,
          fontWeight: 'normal',
          fontSize: 10,
          code_type: 'EAN13',
          source_data: 'plankNum',
        },
      },
    ],
    tag_width: 60,
    tag_height: 40,
    rectInfo: {
      left: 512.615,
      top: 263.40999999999997,
    },
  },
}
const partSign = [
  {
    label: 'printPage.editPage.locIndex',
    value: 'wardrobeNum',
  },
  {
    label: 'printPage.editPage.plankFrontSign',
    value: 'isFrontPlank',
  },
  {
    label: 'printPage.editPage.roomOrder',
    value: 'wardrobeNumToRoom',
  },
  {
    label: 'printPage.editPage.specialMarkup',
    value: 'specialMark',
  },
]

//房间，柜体编号排序方式
const roomSort = [
  {
    label: 'printPage.editPage.alphabetically',
    value: 'letters',
  },
  {
    label: 'printPage.editPage.numberOrder',
    value: 'number',
  },
]
export default {
  dataSourse,
  bgColor,
  codeAttOpeion,
  codeAttOpeionK,
  defaultTagTem,
  partSign,
  roomSort,
}
